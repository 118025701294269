import * as React from "react";
import Background from "../components/background";
import Seo from "../components/seo";
import data from "../content/content.json";
const Privacy = () => (
  <>
    <Seo />
    <Background />
    <div className="rawTextSite">
      <h1>{data.footer.downloadCenter}</h1>
      <a href="/sample.pdf" download>sample.pdf</a>
    </div>
  </>
);

export default Privacy;
